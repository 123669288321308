import React from 'react'

import { SvgProps } from '../../types'
import { Icon } from '../../Icon'

export const CH: React.FC<SvgProps> = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      {...props}
    >
      <path fill="#d80027" d="M0 0h512v512H0z" />
      <path
        fill="#eee"
        d="M389.6 211.5h-89v-89h-89.1v89h-89v89h89v89h89v-89h89z"
      />
    </Icon>
  )
}
