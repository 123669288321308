import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  LoginRequest,
  RegisterRequest,
  ResetPasswordRequest,
  UpdatePasswordRequest,
  User,
} from '../../../vanilla/types'

import { AuthContextState, AuthProviderProps, AuthStatus } from './types'

const AuthContext = createContext<AuthContextState>({} as AuthContextState)

export const AuthProvider: FC<AuthProviderProps> = ({
  children,
  auth,
  onLogout,
  onUser,
}) => {
  const [status, setStatus] = useState<AuthStatus>('loading')
  const [user, setUser] = useState<User | null>(null)

  useEffect(() => {
    const user = auth.getUser()
    if (auth.getUser()) {
      setStatus('authenticated')
      setUser(user)
      onUser?.(user)
    } else {
      setStatus('unauthenticated')
      setUser(null)
      onUser?.(null)
    }
  }, [onUser])

  const logout = useCallback(async () => {
    const { data } = await auth.logout()
    setStatus('unauthenticated')
    onLogout?.()
    window.location.href = data.return_to
  }, [auth, onLogout])

  const login = useCallback(
    async ({ callbackFn, redirect = true, ...req }: LoginRequest) => {
      const { data } = await auth.login(req)
      setStatus('authenticated')

      if (callbackFn) await callbackFn()

      if (!redirect) {
        fetch(data.return_to)
      } else {
        window.location.href = data.return_to
      }
    },
    [auth],
  )

  const register = useCallback(
    async ({ callbackFn, ...req }: RegisterRequest) => {
      const { data } = await auth.register(req)
      setStatus('authenticated')

      if (callbackFn) await callbackFn()
      window.location.href = data.redirect_uri
    },
    [auth],
  )

  const resetPassword = useCallback(
    async (userId: string, req: ResetPasswordRequest) => {
      const { data } = await auth.resetPassword(userId, req)
      setStatus('authenticated')
      window.location.href = data.return_to
    },
    [auth],
  )

  const updatePassword = useCallback(
    async (req: UpdatePasswordRequest) => {
      const { data } = await auth.updatePassword(req)
      setStatus('authenticated')
      window.location.href = data.return_to
    },
    [auth],
  )

  const context = useMemo(
    () => ({
      auth,
      user,
      status,
      logout,
      login,
      register,
      resetPassword,
      updatePassword,
    }),
    [auth, user, status, logout, register, resetPassword, updatePassword],
  )

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
}

export const useAuthContext = () => useContext(AuthContext)
